import React from "react";
import MoneyInput from "@rschpdr/react-money-input";
import {TextField} from "@material-ui/core";
import {i18n} from "../../translate/i18n";

const CurrencyFieldATM = ({ ...props }) => (
    <MoneyInput
        customInput={TextField}
        variant="outlined"
        currencyConfig={{
            locale: i18n.t("locale"),
            currencyCode: i18n.t("currencyCode"),
            currencyDisplay: "symbol"
        }}
        {...props}
    />
);

export default CurrencyFieldATM;