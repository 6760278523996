import React, {useState} from "react"
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CheckboxList from "./checkboxlist";
import {CloseIcon} from "react-modal-image/lib/icons";

const ConnectionDrawer = props => {

    const { toggleDrawer, state, anchor, handleFiltrarDashboard } = props;
    const [ checked, setChecked ] = useState([]);

    if (state.sessionsChecked.length > 0 && checked.length === 0) {
        const newChecked = [...state.sessionsChecked];
        setChecked(newChecked);
    }

    const handleChangeCheckboxItem = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const list = (anchor) => {
        const items = state.sessions;
        return (
            <Box sx={{ width: 'auto', paddingTop: 7 }} role="presentation">
                <IconButton
                    variant="outlined"
                    aria-label="close"
                    onClick={toggleDrawer(anchor, false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 56,
                        color: 'success.main',
                    }}>
                    <CloseIcon
                        sx={{
                            color: 'success.main',
                            fill: 'success.main',
                        }}/>
                </IconButton>
                <CheckboxList items={items}
                              labelName={'name'}
                              idName={'id'}
                              checkedList={checked}
                              onChangeCheckboxItem={handleChangeCheckboxItem}
                              onFiltrar={handleFiltrarDashboard}/>
            </Box>
        );
    };

    return (
        <Drawer
            anchor={"top"}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
        >
            {list(anchor)}
        </Drawer>
    );
}
export default ConnectionDrawer;