import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";

import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";

import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
  },

  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    minHeight: "48px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    maxHeight: "55px",
  },
  st1: {
    fill: "#f3f5ff"
  },
  st0: {
    fill: "#09C95B"
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: {
    minHeight: "48px",
  },
  content: {
    flex: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const LoggedInLayout = ({ children }) => {
  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (document.body.offsetWidth > 600) {
      setDrawerOpen(false);
    }
  }, []);

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  const drawerClose = () => {
    if (document.body.offsetWidth < 600) {
      setDrawerOpen(false);
    }
  };

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <div className={classes.root}>
      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems drawerClose={drawerClose} />
        </List>
        <Divider />
      </Drawer>
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        color={process.env.NODE_ENV === "development" ? "primary" : "primary"}
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawerOpen(!drawerOpen)}
            className={clsx(
              classes.menuButton,
              drawerOpen && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 948 386" width="150">
                <g>
                  <path className={classes.st1} d="M209.24,158.75c-4.24-4.4-9.95-7.86-16.98-10.28c-6.97-2.4-15.65-3.62-25.78-3.62c-8.4,0-16.4,0.64-23.8,1.91   c-7.4,1.27-13.12,2.56-17,3.83l-1.18,0.39l1.98,12.15c0.53,3.29,2.37,6.2,5.18,8.19c2.99,2.12,6.81,2.97,10.5,2.31   c0.73-0.13,1.49-0.26,2.26-0.38c5.73-0.92,12.15-1.4,19.06-1.4c4.98,0,9.15,0.6,12.4,1.8c3.19,1.18,5.78,2.84,7.7,4.93   c1.92,2.1,3.29,4.57,4.06,7.32c0.79,2.85,1.2,5.14,1.2,8.3v2.92c-6.8-0.74-27.46-2.67-41.81,0.55c-6.71,1.51-12.76,3.65-17.88,6.8   c-5.19,3.18-9.36,7.36-12.39,12.42c-3.05,5.09-4.59,11.38-4.59,18.7c0,7.61,1.36,14.11,4.04,19.32c2.69,5.24,6.47,9.55,11.24,12.82   c4.72,3.24,10.5,5.62,17.18,7.07c6.59,1.44,13.94,2.16,21.86,2.16c11.85,0,22.67-0.68,32.15-2.02c9.39-1.33,16.54-2.49,21.25-3.44   l1.17-0.24v-76.07c0-7.35-0.91-14.2-2.69-20.36C216.55,168.57,213.48,163.16,209.24,158.75z M188.82,250.19   c-4.7,0.78-11.37,1.18-19.84,1.18c-7.39,0-13.3-1.25-17.57-3.71c-4.06-2.34-6.04-6.53-6.04-12.8c0-3.14,0.75-5.75,2.23-7.76   c1.51-2.07,3.55-3.7,6.07-4.85c2.63-1.2,5.61-2.03,8.87-2.48c3.34-0.46,6.68-0.7,9.95-0.7c3.76,0,7.23,0.23,10.31,0.69   c2.47,0.37,4.49,0.74,6.03,1.1V250.19z"></path>
                  <path className={classes.st1} d="M198.44,126.63l17.69-19.16h-22.69c-9.07,0-17.68,3.82-23.04,10.21l-13.17,15.71h25.06   C188.59,133.38,194.48,130.92,198.44,126.63z"></path>
                  <path className={classes.st1} d="M339.92,173.84v101.79h30.56v-96.88c0-4.48,3.64-8.12,8.12-8.12h31.07v-30.44h-36.1   C355.01,140.18,339.92,155.28,339.92,173.84z"></path>                 
                  <path className={classes.st1} d="M443.58,86.24c-11.19,0-19.63,8.22-19.63,19.11c0,11.03,8.44,19.35,19.63,19.35   c11.33,0,19.88-8.32,19.88-19.35C463.46,94.45,454.91,86.24,443.58,86.24z"></path>
                  <path className={classes.st1} d="M594.28,159.55c-12.42-12.5-29.63-19.38-48.46-19.38c-38.71,0-67.91,29.14-67.91,67.79   c0,18.91,6.86,36.17,19.32,48.6c12.4,12.38,29.58,19.19,48.36,19.19c38.72,0,67.91-29.15,67.91-67.8   C613.5,189.2,606.67,172.01,594.28,159.55z M545.59,246.36c-21.45,0-36.43-15.79-36.43-38.4c0-22.25,15.32-38.4,36.43-38.4   c21.24,0,36.65,16.15,36.65,38.4C582.24,230.57,567.17,246.36,545.59,246.36z"></path>
                  <rect x="428.29" y="140.18" className={classes.st1} width="30.7" height="135.45"></rect>
                  <path className={classes.st1} d="M292.74,243.75v-34.3h-30.56v44.76c0,11.81,9.61,21.42,21.42,21.42h35.61v-28.14h-22.73   C294.41,247.48,292.74,245.81,292.74,243.75z"></path>
                  <path className={classes.st0} d="M292.81,111.74h-16.55c-7.76,0-14.08,6.32-14.08,14.08v14.36h-28.43v30.62h28.43v28.43h30.63V170.8h28.43   v-30.62h-28.43V111.74z"></path>
                </g>
            </svg>
          </Typography>
          {user.id && <NotificationsPopOver />}

          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuOpen}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleOpenUserModal}>
                {i18n.t("mainDrawer.appBar.user.profile")}
              </MenuItem>
              <MenuItem onClick={handleClickLogout}>
                {i18n.t("mainDrawer.appBar.user.logout")}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {children ? children : null}
      </main>
    </div>
  );
};

export default LoggedInLayout;
