import React, {useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import {i18n} from "../../translate/i18n";

import ContactModal from "../ContactModal";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ConfirmationModal from "../ConfirmationModal";
import api from "../../services/api";
import {toast} from "react-toastify";
import toastError from "../../errors/toastError";
import ContactBudgetModal from "../ContactBudgetModal";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		backgroundColor: "#eee",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "62px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: "#eee",
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 160,
		height: 160,
	},

	contactBudget: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},

	},
	contactHeader: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactBudgetItem: {
		display: "flex"
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
	},
}));

const ContactDrawer = ({ open, handleDrawerClose, contact, loading, updateContact }) => {
	const classes = useStyles();

	const [modalOpen, setModalOpen] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [deletingBudget, setDeletingBudget] = useState(null);
	const [selectedBudgetId, setSelectedBudgetId] = useState(null);
	const [budgetModalOpen, setBudgetModalOpen] = useState(false);

	if (contact && contact.budgets) {
		contact.budgets.sort(function(a,b){return new Date(b.budgetDate).getTime() - new Date(a.budgetDate)	.getTime()});
	}

	const handleDeleteBudget = async (budgetId) => {
		try {
			await api.delete(`/contacts/budget/${budgetId}`);
			toast.success(i18n.t("contactDrawer.budget.deleted"));
			if (!contact.budgets) {
				contact.budgets = [];
			}
			for (let i = 0; i < contact.budgets.length; i++) {
				let element = contact.budgets[i];
				if (element.id === budgetId) {
					contact.budgets.splice(i,1);
					if (updateContact) {
						updateContact(contact);
					}
					break;
				}
			}

		} catch (err) {
			toastError(err);
		}
		setDeletingBudget(null);
	};

	const handleEditBudget = (budgetId) => {
		setSelectedBudgetId(budgetId);
		setBudgetModalOpen(true);
	};

	const handleCloseBudgetModal = () => {
		setSelectedBudgetId(null);
		setBudgetModalOpen(false);
	};

	const handleSaveBudgetModal = (data) => {
		setSelectedBudgetId(null);
		setBudgetModalOpen(false);
		if (!contact.budgets) {
			contact.budgets = [];
		}
		let localizou = false;
		for (let i = 0; i < contact.budgets.length; i++) {
			let element = contact.budgets[i];
			if (element.id === data.id) {
				contact.budgets[i] = data;
				localizou = true
				break;
			}
		}
		if (!localizou) {
			contact.budgets.push(data);
		}
		if (updateContact) {
			updateContact(contact);
		}
	}

	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="right"
			open={open}
			PaperProps={{ style: { position: "absolute" } }}
			BackdropProps={{ style: { position: "absolute" } }}
			ModalProps={{
				container: document.getElementById("drawer-container"),
				style: { position: "absolute" },
			}}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<div className={classes.header}>
				<IconButton onClick={handleDrawerClose}>
					<CloseIcon />
				</IconButton>
				<Typography style={{ justifySelf: "center" }}>
					{i18n.t("contactDrawer.header")}
				</Typography>
			</div>
			{loading ? (
				<ContactDrawerSkeleton classes={classes} />
			) : (
				<div className={classes.content}>
					<Paper square variant="outlined" className={classes.contactHeader}>
						<Avatar
							alt={contact.name}
							src={contact.profilePicUrl}
							className={classes.contactAvatar}
						></Avatar>

						<Typography>{contact.name}</Typography>
						<Typography>
							<Link href={`tel:${contact.number}`}>{contact.number}</Link>
						</Typography>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => setModalOpen(true)}
						>
							{i18n.t("contactDrawer.buttons.edit")}
						</Button>
					</Paper>
					<Paper square variant="outlined" className={classes.contactDetails}>
						<ContactModal
							open={modalOpen}
							onClose={() => setModalOpen(false)}
							contactId={contact.id}
						></ContactModal>
						<Typography variant="subtitle1">
							{i18n.t("contactDrawer.extraInfo")}
						</Typography>
						{contact?.extraInfo?.map(info => (
							<Paper
								key={info.id}
								square
								variant="outlined"
								className={classes.contactExtraInfo}
							>
								<InputLabel>{info.name}</InputLabel>
								<Typography component="div" noWrap style={{ paddingTop: 2 }}>
									<MarkdownWrapper>{info.value}</MarkdownWrapper>
								</Typography>
							</Paper>
						))}
					</Paper>
					<Paper square variant="outlined" className={classes.contactDetails}>
						<ContactModal
							open={modalOpen}
							onClose={() => setModalOpen(false)}
							contactId={contact.id}
						></ContactModal>
						<Typography variant="subtitle1">
							{i18n.t("contactDrawer.budget.header")}
						</Typography>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => handleEditBudget(null)}
						>
							{i18n.t("contactDrawer.budget.new")}
						</Button>
						<ContactBudgetModal
							open={budgetModalOpen}
							onClose={handleCloseBudgetModal}
							onSave={handleSaveBudgetModal}
							aria-labelledby="form-dialog-title"
							budgetId={selectedBudgetId}
							contactId={contact.id}
						></ContactBudgetModal>
						<ConfirmationModal
							title={
								`${i18n.t("contactDrawer.budget.modal.delete.title")}`
							}
							open={confirmOpen}
							onClose={setConfirmOpen}
							onConfirm={(e) => handleDeleteBudget(deletingBudget.id)}
						>
							{`${i18n.t("contactDrawer.budget.modal.delete.message")} ${deletingBudget?.budgetNumber||""}?`}
						</ConfirmationModal>
						{contact.budgets?.map(budget => (
							<Paper
								key={budget.id}
								square
								variant="outlined"
								className={classes.contactBudget}
							>
								<Grid container spacing={2}>
									<Grid item xs={4}>
										<InputLabel>{i18n.t("contactDrawer.budget.date")}</InputLabel>
										<span>{budget.budgetDate
											? new Date(budget.budgetDate)?.toLocaleDateString(i18n.t("locale"))
											: ""}</span>
									</Grid>
									<Grid item xs={6}>
										<InputLabel>{i18n.t("contactDrawer.budget.number")}</InputLabel>
										<span>{budget.budgetNumber?.toString()}</span>
									</Grid>
									<Grid item xs={2}>
										<IconButton
											size="small"
											onClick={() => handleEditBudget(budget.id)}
										>
											<EditIcon />
										</IconButton>
									</Grid>
									<Grid item xs={4}>
										<InputLabel>{i18n.t("contactDrawer.budget.status.label")}</InputLabel>
										<Typography component="div" noWrap style={{ paddingTop: 2 }}>
											<MarkdownWrapper>{i18n.t("contactDrawer.budget.status."+budget.budgetStatus?.toLowerCase())}</MarkdownWrapper>
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<InputLabel>{i18n.t("contactDrawer.budget.amount")}</InputLabel>
										<Typography component="div" noWrap style={{ paddingTop: 2 }}>
											<MarkdownWrapper>{budget.budgetAmount ? parseFloat(budget.budgetAmount)?.toLocaleString(i18n.t("locale"), {
												style: 'currency',
												currency: i18n.t("currencyCode")
											}): ""}</MarkdownWrapper>
										</Typography>
									</Grid>
									<Grid item xs={2}>
										<IconButton
											size="small"
											onClick={(e) => {
												setDeletingBudget(budget);
												setConfirmOpen(true);
											}}
										>
											<DeleteOutlineIcon />
										</IconButton>
									</Grid>
								</Grid>
							</Paper>
						))}
					</Paper>
				</div>
			)}
		</Drawer>
	);
};

export default ContactDrawer;
