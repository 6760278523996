import React, {useEffect, useRef, useState} from "react";

import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import {toast} from "react-toastify";

import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import {i18n} from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import {KeyboardDatePicker} from "@material-ui/pickers";

import {InputLabel, MenuItem, Select} from "@material-ui/core";
import {Grid} from "@mui/material";
import CurrencyFieldATM from "../Currency/currency-field-atm";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	datePicker: {
		paddingTop: "8",
		div: {
			input: {
				padding: "10"
			}
		}
	},
	space: {
		margin: "15"
	}
}));

const BudgetSchema = Yup.object().shape({
	budgetDate: Yup.date()
		.required(i18n.t("budgetModal.errors.fieldrequired")),
	budgetNumber: Yup.number()
		.min(1, i18n.t("budgetModal.errors.budgetminnumber"))
		.required(i18n.t("budgetModal.errors.fieldrequired")),
	budgetStatus: Yup.string()
		.required(i18n.t("budgetModal.errors.fieldrequired")),
	budgetAmount: Yup.number()
		.min(0.01, i18n.t("budgetModal.errors.budgetminamount"))
		.required(i18n.t("budgetModal.errors.fieldrequired")),
});

const ContactBudgetModal = ({ open, onClose, budgetId, contactId, initialValues, onSave }) => {
	const classes = useStyles();
	const isMounted = useRef(true);

	const initialState = {
		budgetDate: new Date(),
		budgetStatus: 'OPEN',
		budgetNumber: 0,
		budgetAmount: 0.0,
		contactId: contactId
	};

	const [budget, setBudget] = useState(initialState);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const fetchBudget = async () => {
			if (initialValues) {
				setBudget(prevState => {
					return { ...prevState, ...initialValues };
				});
			}
			if (!budgetId) return;

			try {
				const { data } = await api.get(`/contacts/budget/${budgetId}`);
				if (isMounted.current) {
					setBudget(data);
				}
			} catch (err) {
				toastError(err);
			}
		};
		fetchBudget();
	}, [budgetId, open, initialValues]);

	const handleClose = () => {
		onClose();
		setBudget(initialState);
	};

	const handleSaveBudget = async values => {
		try {
			if (budgetId) {
				const { data } = await api.put(`/contacts/budget/${budgetId}`, values);
				if (onSave) {
					onSave(data);
				}
				handleClose();
			} else {
				const { data } = await api.post("/contacts/budget", values);
				if (onSave) {
					onSave(data);
				}
				handleClose();
			}
			toast.success(i18n.t("budgetModal.success"));
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
				<DialogTitle id="form-dialog-title">
					{budgetId
						? `${i18n.t("budgetModal.title.edit")}`
						: `${i18n.t("budgetModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={budget}
					enableReinitialize={true}
					validationSchema={BudgetSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveBudget(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{props => (
						<Form>
							<DialogContent dividers>
								<Typography variant="subtitle1" gutterBottom>
									{i18n.t("budgetModal.form.mainInfo")}
								</Typography>
								<Grid container justify="space-around" rowSpacing={1} columnSpacing={2}>
									<Grid item xs={6}>
										<KeyboardDatePicker
											autoFocus
											id="budgetDate"
											name="budgetDate"
											label={i18n.t("budgetModal.form.date")}
											format="dd/MM/yyyy"
											inputVariant="outlined"
											variant="outlined"
											margin="dense"
											disableToolbar
											required
											value={props.values.budgetDate}
											className={classes.datePicker}
											onChange={value => props.setFieldValue("budgetDate", value)}
											KeyboardButtonProps={{
												"aria-label": "change date"
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<Field
											as={TextField}
											label={i18n.t("budgetModal.form.budgetNumber")}
											type="number"
											name="budgetNumber"
											value={props.values.budgetNumber}
											onChange={(e) => props.setFieldValue('budgetNumber', e.target.value)}
											error={props.touched.budgetNumber && Boolean(props.errors.budgetNumber)}
											helperText={props.touched.budgetNumber && props.errors.budgetNumber}
											variant="outlined"
											margin="dense"
											required
										/>
									</Grid>
									<Grid item xs={6} className={classes.space}>
										<>
											<InputLabel id="budget-status-input-label">
												{i18n.t("budgetModal.form.budgetStatus")}
											</InputLabel>
											<Field
												as={Select}
												id="budget-status"
												name="budgetStatus"
												label={i18n.t("budgetModal.form.budgetStatus")}
												labelId="budget-status-label"
												value={props.values.budgetStatus}
												onChange={(e) => props.setFieldValue('budgetStatus', e.target.value)}
												error={props.touched.budgetStatus && Boolean(props.errors.budgetStatus)}
												variant="outlined"
												margin="dense"
												required
												className={classes.space}
											>
												<MenuItem value="OPEN">{i18n.t("contactDrawer.budget.status.open")}</MenuItem>
												<MenuItem value="CLOSED">{i18n.t("contactDrawer.budget.status.closed")}</MenuItem>
												<MenuItem value="LOST">{i18n.t("contactDrawer.budget.status.lost")}</MenuItem>
											</Field>
										</>
									</Grid>
									<Grid item xs={6}>
										<Field
											as={CurrencyFieldATM}
											label={i18n.t("budgetModal.form.amount")}
											id="amount"
											name="budgetAmount"
											onChange={props.handleChange}
											value={props.values.budgetAmount}
											error={!!props.errors.budgetAmount}
											className={classes.space}
											helperText={props.errors.budgetAmount && props.touched.budgetAmount && props.errors.budgetAmount}
										/>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={props.isSubmitting}
									variant="outlined"
								>
									{i18n.t("budgetModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={props.isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{budgetId
										? `${i18n.t("budgetModal.buttons.okEdit")}`
										: `${i18n.t("budgetModal.buttons.okAdd")}`}
									{props.isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ContactBudgetModal;
