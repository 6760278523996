import React, {Fragment, useContext, useEffect, useState} from "react"

import {AuthContext} from "../../context/Auth/AuthContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {Button} from "@material-ui/core";
import UsuarioDrawer from "./usuariodrawer";
import {makeStyles} from "@material-ui/core/styles";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import {i18n} from "../../translate/i18n";
import ConnectionDrawer from "./connectiondrawer";

const useStyles = makeStyles(theme => ({
	button: {
		position: "relative",
		top: 20,
		left: 20
	},
}));

const Dashboard = () => {

	const classes = useStyles();
	const { height, width } = useWindowDimensions();
	const { user } = useContext(AuthContext);
	let [ state, setState ] = useState({
		users: [user],
		usersChecked: [],
		sessions: [],
		sessionsChecked: [],
		link: '',
	});
	const anchorDrawer = 'top';
	const anchorConnectionsDrawer = 'topConnection';

	useEffect(() => {
		const delayDebounceFn = setTimeout(async () => {
			await getAllUsers();
			await getAllSessions();
			getIframeLink();
		}, 500)
		return () => clearTimeout(delayDebounceFn)
	}, []);

	const getAllUsers = async () => {
		try {
			if (user.profile.includes('admin')) {
				const {data} = await api.get("/users/");
				state.users = data.users;
			}
			if (state.usersChecked.length === 0) {
				state.usersChecked = state.users;
			}
			setState({...state});
		} catch (err) {
			toastError(err);
		}
	}

	const getAllSessions = async () => {
		try {
			const {data} = await api.get("/whatsapp/");
			state.sessions = data;
			if (state.sessionsChecked.length === 0) {
				state.sessionsChecked = state.sessions;
			}
			setState({...state});
		} catch (err) {
			toastError(err);
		}
	}
	const getIframeLink = async() => {
		if (state.link && state.link.length > 0) {
			return;
		}
		try {
			const usersIds = state.usersChecked.map(user => user.id).join(',');
			const sessionsIds = state.sessionsChecked.map(conn => conn.id).join(',');
			const { data } = await api.get("/dashboard/", {
				params: {
					users: usersIds,
					sessions: sessionsIds
				},
			})
			state.link = data.link;
			setState({...state});
		} catch (err) {
			toastError(err)
		}

	}

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}
		setState({ ...state, [anchor]: open });
	};

	const toggleSessionsDrawer = (anchor, open) => (event) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}
		setState({ ...state, [anchor]: open });
	};

	const handleFiltrarDashboard = (list) => () => {
		state.usersChecked = list;
		state.link = '';
		state.top = false;
		setState({ ...state });
		const delayDebounceFn = setTimeout(() => {
			getIframeLink()
		}, 500);
	}

	const handleSessionsFiltrarDashboard = (list) => () => {
		state.sessionsChecked = list;
		state.link = '';
		state.top = false;
		state.topConnection=false;
		setState({ ...state });
		const delayDebounceFn = setTimeout(() => {
			getIframeLink()
		}, 500);
	}

	return (
		<div>
			{user.profile === "admin"
				? <Fragment key={anchorDrawer}
				>
					<Button
						className={classes.button}
						variant="outlined"
						onClick={toggleDrawer(anchorDrawer, true)}
					>
						{i18n.t("dashboard.buttons.users")}
					</Button>
					<Button
						style={{marginLeft: 10}}
						className={classes.button}
						variant="outlined"
						onClick={toggleSessionsDrawer(anchorConnectionsDrawer, true)}
					>
						{i18n.t("dashboard.buttons.sessions")}
					</Button>
					<UsuarioDrawer
						anchor={anchorDrawer}
						toggleDrawer={toggleDrawer}
						state={state}
						handleFiltrarDashboard={handleFiltrarDashboard}/>
					<ConnectionDrawer
						anchor={anchorConnectionsDrawer}
						toggleDrawer={toggleSessionsDrawer}
						state={state}
						handleFiltrarDashboard={handleSessionsFiltrarDashboard}/>
				</Fragment>
				: <div></div>}
			<iframe title="Dashboard"
					src={state.link}
					frameBorder="0"
					width={width - 72}
					height={height - 90}
			></iframe>
		</div>
	)
}

export default Dashboard