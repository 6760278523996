import React, {useEffect} from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {Checkbox, ListItemText} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import {makeStyles} from "@material-ui/core/styles";
import {i18n} from "../../translate/i18n";


const useStyles = makeStyles((theme) => ({
	badge: {
		right: "-10px",
	}
}));

const TicketsFilterSelect = ({
	tab,
 	showAll,
	onChange,
	pendingCount
}) => {
	const classes = useStyles();
	const filterTabChecked = JSON.parse(localStorage.getItem("filterTabChecked-"+tab));
	const filters = [
		{
			enum: 'AWAITING',
			label: i18n.t("ticketsFilterSelect.awaiting"),
			checked: filterTabChecked ? filterTabChecked['AWAITING']:false
		},
		{
			enum: 'ALL_TICKETS',
			label: i18n.t("ticketsFilterSelect.allTickets"),
			checked: filterTabChecked ? filterTabChecked['ALL_TICKETS']: false
		},
		{
			enum: 'MY_TICKETS',
			label: i18n.t("ticketsFilterSelect.myTickets"),
			checked: filterTabChecked ? filterTabChecked['MY_TICKETS']: true
		},
		{
			enum: 'UNREAD',
			label: i18n.t("ticketsFilterSelect.unread"),
			checked: filterTabChecked ? filterTabChecked['UNREAD']: false
		}
	];
	const [ticketFilters, setTicketFilters] = React.useState([... showAll? filters: filters.filter(p => p.enum !=='ALL_TICKETS')]);
	const [ticketFiltersSelected, setTicketFiltersSelected] = React.useState([...ticketFilters.filter(p => p.checked)]);

	const handleTicketFilterChange = (event) => {
		ticketFilters.forEach(e => e.checked = false);
		for (const item of event.target.value) {
			for (const element of ticketFilters) {
				if (item.enum === element.enum) {
					item.checked = true;
					element.checked = true;
				}
			}
		}
		if (ticketFilters.filter(p => p.checked).length === 0) {
			ticketFilters.filter(p => p.enum === 'MY_TICKETS')[0].checked = true;
			setTicketFiltersSelected([...'MY_TICKETS']);
		} else {
			setTicketFiltersSelected(event.target.value);
		}

		localStorage.setItem("filterTabChecked-"+tab, JSON.stringify({
			AWAITING: ticketFilters.filter(i => i.enum === 'AWAITING')[0].checked,
			ALL_TICKETS: ticketFilters.filter(i => i.enum === 'ALL_TICKETS')[0].checked,
			MY_TICKETS: ticketFilters.filter(i => i.enum === 'MY_TICKETS')[0].checked,
			UNREAD: ticketFilters.filter(i => i.enum === 'UNREAD')[0].checked
		}));
		setTicketFilters([...ticketFilters]);
		onChange(ticketFilters.filter(p => p.checked).map(p => p.enum));
	};

	useEffect(() => {
		onChange(ticketFilters.filter(p => p.checked).map(p => p.enum))
		return () => clearTimeout()
	}, [ticketFilters])

	return (
		<FormControl fullWidth margin="dense" size="small" style={{marginTop: "4px"}}>
			<Select
				multiple
				variant="outlined"
				id="demo-select-small"
				value={ticketFiltersSelected}
				onChange={handleTicketFilterChange}
				renderValue={() => i18n.t("ticketsFilterSelect.renderValue")}
			>
				{ticketFilters.length > 0 &&
				ticketFilters.map(item => (
					<MenuItem dense key={item.enum} value={item}>
						<Checkbox
							size="small"
							color="primary"
							checked={item.checked}
						/>
						<ListItemText id={item.enum}>
							{ item.enum==='AWAITING'? (
								<Badge
									overlap="rectangular"
									className={classes.badge}
									badgeContent={pendingCount}
									color="secondary"
								>{item.label}</Badge>
								) : item.label}
						</ListItemText>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default TicketsFilterSelect;