import React from "react";

import {Avatar, CardHeader} from "@material-ui/core";

import {i18n} from "../../translate/i18n";

const TicketInfo = ({ contact, ticket, onClick }) => {
	return (
		<CardHeader
			onClick={onClick}
			style={{ cursor: "pointer", padding: 10 }}
			titleTypographyProps={{ noWrap: true }}
			subheaderTypographyProps={{ noWrap: true }}
			avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
			title={`${contact.name} #${ticket.id}`}
			subheader={
				(ticket.user && ticket.queue &&
				`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}. ${i18n.t("messagesList.header.assignedQueue")} ${ticket.queue.name}`) ||
				(ticket.user &&
					`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}.`) ||
				(ticket.queue &&
				`${i18n.t("messagesList.header.assignedQueue")} ${ticket.queue.name}`)
			}
		/>
	);
};

export default TicketInfo;
