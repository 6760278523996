import React from "react"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {i18n} from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    button: {
        position: "relative",
        left: 10,
        marginBottom: 10,
    },
}));

const CheckboxList = props => {

    const { items, labelName, idName, checkedList, onChangeCheckboxItem, onFiltrar } = props;
    const classes = useStyles();

    return (
        <div>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {items.map((value) => {
                    const labelId = `checkbox-list-label-${value[idName]}`;
                    return (
                        <ListItem
                            key={value[idName]}
                            disablePadding
                        >
                            <ListItemButton role={undefined} onClick={onChangeCheckboxItem(value)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checkedList.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${value[labelName]}`} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
            <Button
                variant="outlined"
                onClick={onFiltrar(checkedList)}
                className={classes.button}
            >
                {i18n.t("dashboard.buttons.filter")}
            </Button>
        </div>
    );
}
export default CheckboxList;